import React from 'react'

import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BlogCard from '../components/BlogCard'

const IndexPage = ({ data }) => (
  <Layout>
    {data.allJobsJson.edges.map((edge, key) => (
      <BlogCard
        key={key}
        post={{
          ...edge.node,
          tag_list_array: edge.node.categories,
        }}
      />
    ))}
  </Layout>
)

export default IndexPage

export const IndexPageQuery = graphql`
    {
      allJobsJson(
        sort: { order: DESC, fields: [isoDate] }
        limit: 1000
      ) {
        edges {
          node {
            guid
            title
            categories
            companyLogo
            author {name: a10_name}
            isoDate
          }
        }
      }
    }
`

