import React from 'react'

import kebabCase from "lodash/kebabCase"
import { Avatar, Card, CardContent, Chip, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, makeStyles, Typography } from '@material-ui/core'
import { navigate } from 'gatsby'


const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: "10px"
  }
}));

const BlogCard = ({ post }) => {

  const classes = useStyles();

  const postedDays = Math.round((Date.now() - Date.parse(post.isoDate))/(24*3600*1000));
  const postedText = postedDays === 0 ? "today" : `${postedDays}d`

  if (post.categories == undefined) {
    post.categories = [];
  }

  let validPromo = post.promoMainIsoDate != undefined && post.promoMainIsoDate != ""
  if (validPromo) {
    validPromo = Math.round((Date.now() - Date.parse(post.promoMainIsoDate))/(24*3600*1000)) <= 0
  }

  return (
  <Card className={classes.card} style={validPromo ? {background: '#e8ebf7'} : {}}>
    <CardContent>
      <List>
      <ListItem key={`top-${post.guid}`} alignItems="flex-start" button onClick={
        event => {
          navigate(`/jobs/${post.guid}`)
        }
      }>
        <ListItemAvatar>
          {/* fetching these images blocks the rendering, we need to bring back gatsby-images  */}
          <Avatar alt={post.author.name} variant="rounded" src={`/logo/${post.companyLogo}`} />
        </ListItemAvatar>
        <ListItemText
          primary={post.title.split(" at ")[0]}
          secondary={
            <React.Fragment>
              <Typography
                component="p"
                variant="body2"
                color="textSecondary"
              >
              {post.title.split(" at ")[1]}
              </Typography>
                {post.categories.map((tag, key) => (
                  <Chip key={`top-${post.guid}-${tag}`} label={`#${tag}`} size='small' clickable={true} onClick={
                    event => {
                      event.stopPropagation()
                      navigate(`/tags/${kebabCase(tag)}`)
                    }
                  }/>
                ))}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <Typography color="textSecondary">
            {`${postedText}`}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
      </List>
    </CardContent>
  </Card>
  )
}

export default BlogCard
